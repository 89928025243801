import React from "react";

const PanelToggleBtn = ({ target, name, togglePanel, panelsVisible }) => {
  return (
    <button
      className="btn text-secondary border-0 bg-transparent w-100 mt-5 mb-3 font-weight-bold border text-left d-flex align-items-center justify-content-start"
      onClick={() => togglePanel(target)}
      style={{ fontSize: 24 }}
    >
      {name}
      <i
        className={`fa fa-caret-${
          panelsVisible.includes(target) ? "up" : "down"
        } ml-2`}
      />
    </button>
  );
};

export default PanelToggleBtn;
