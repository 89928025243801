import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import jwt_decode from "jwt-decode";
// Pages
import useStore from "./context/useStore";
import { authActions } from "./context/actions/authActions";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import Sidenav from "./components/common/layout/Sidenav";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { constantActions } from "./context/actions/constantActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import TopHeader from "./components/common/layout/TopHeader";
import Colors from "./theme/variables.scss";
import { loadReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token, API_URL } from "./config";
import Axios from "axios";
import Register from "./components/pages/Register/Register";
import Profile from "./components/pages/Profile/Profile";
import CustomModal from "./components/common/CustomModal/CustomModal";
import Test from "./components/pages/Test";
import Logout from "./components/pages/Logout/Logout";
import MissionList from "./components/pages/Missions/MissionList/MissionList";
import MissionDetail from "./components/pages/Missions/MissionDetail/MissionDetail";
import MissionComparison from "./components/pages/Missions/MissionComparison/MissionComparison";

window.borderRadius = Colors.borderRadius;

var firstLoad = true;
const App = () => {
  const [state, dispatch] = useStore();
  const [sidenavOpen, setSidenavOpen] = useState(false);

  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  Axios.defaults.withCredentials = true;
  Axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  console.log("GLOBAL STATE", state);

  useEffect(() => {
    if (localStorage.jwtToken && firstLoad) {
      firstLoad = false;
      // Set auth token header auth
      //tools.setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);

      // Set user and isAuthenticated
      dispatch({
        type: AUTH_TYPES.SET_CURRENT_USER,
        payload: decoded,
      });

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user

        actions.logoutUser();
        // Clear current Profile
        // store.dispatch(clearCurrentProfile())
        // Redirect to login
      }
    }

    if (!localStorage.jwtToken) {
      Axios.get(API_URL + "auth/me")
        .then((res) => {
          const decoded = jwt_decode(res.data);
          dispatch({
            type: AUTH_TYPES.SET_CURRENT_USER,
            payload: decoded,
          });
        })
        .catch((err) => {
          actions.logoutUser();
        });
    }
    c_actions.getConstants();
    loadReCaptcha(recaptcha_token, () => {
      console.log("captcha loaded !");
    });
  }, []);

  useEffect(() => {
    if (!state.auth.isAuthenticated) {
      document.querySelector("#main-content").style.width = "100%";
    }
  }, [state.auth.isAuthenticated]);

  const sidenavItems = [
    {
      name: "Constats",
      path: "/dashboard",
      icon: "file-contract",
    },
  ];

  return (
    <>
      <Router>
        <div className="wrapper" id="outer-container">
          {state.auth.isAuthenticated && (
            <>
              <TopHeader />

              <Sidenav
                sidenavItems={sidenavItems}
                sidenavOpen={sidenavOpen}
                setSidenavOpen={setSidenavOpen}
              />
            </>
          )}
          <div id="main-content" className="position-relative">
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            {/* Same as */}
            {Object.keys(state.constants.items).length && (
              <Switch>
                <Route
                  path="/renew-password/:token"
                  exact
                  component={RenewPassword}
                />
                {process.env.NODE_ENV == "development" && (
                  <Route path="/test" exact component={Test} />
                )}
                <Route
                  path="/activate-account/:token/:email"
                  exact
                  component={ActivateAccount}
                />
                <Route
                  path="/renew-password/:token/:email"
                  exact
                  component={RenewPassword}
                />
                <Route path="/logout" exact component={Logout} />
                <Route path="/register" exact component={Register} />
                <PrivateRoute path="/" exact component={Dashboard} />
                <PrivateRoute path="/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/constats" exact component={MissionList} />
                <PrivateRoute
                  path="/constats/:id"
                  exact
                  component={MissionDetail}
                />
                <PrivateRoute path="/constats/*" component={MissionComparison} />
                <PrivateRoute path="/profile" exact component={Profile} />
              </Switch>
            )}
          </div>
        </div>
      </Router>
      <CustomModal />
    </>
  );
};

export default App;
