import React, { useState } from "react";
import AnswerChartWrapper from "./AnswerChartWrapper";

const ReportAnswers = ({ report }) => {
  const [isVisible, setIsVisible] = useState(false);

  const getScore = (score) => {
    var stars = [];
    for (var i = 0; i < 5; i++) {
      if (i < score) {
        stars.push(<i className="fa fa-star" />);
      } else {
        stars.push(<i className="far fa-star" />);
      }
    }
    return stars;
  };

  return (
    <>
      {report.sections.map((r, rk) => (
        <div className={`row mx-0 px-3`}>
          <div
            className="col-12 d-flex bg-primary text-white py-3 flex-row d-flex w-100 font-weight-bold justify-content-between"
            style={{ cursor: "pointer" }}
            onClick={() => setIsVisible(isVisible == r.id ? false : r.id)}
          >
            <div>
              SECTION : {r.name}
              <i className="fa fa-caret-down ml-2" />
            </div>
            <div>{getScore(r.score)}</div>
          </div>
          {isVisible == r.id &&
            r.questions.map((q, qk) => {
              return ["2", "3", "4", "6"].includes(q.type + "") ? (
                <AnswerChartWrapper
                  key={`report${rk}${qk}`}
                  question={q}
                  questionNum={qk + 1}
                  finished={report.participations.finished}
                />
              ) : null;
            })}
        </div>
      ))}
      <div className={`row mx-0 px-3`}>
        <div className="col-12 d-flex bg-secondary text-white py-3 flex-row d-flex w-100 font-weight-bold justify-content-between">
          <div>NOTE GLOBALE :</div>
          <div>{getScore(report.score)}</div>
        </div>
      </div>
    </>
  );
};

export default ReportAnswers;
