import React from "react";

const BubbleCount = ({ count }) => {
  return (
    <div
      className={`d-center p-2 ml-2 ${
        count > 0 ? "bg-secondary text-white" : "bg-default text-black"
      }`}
      style={{
        borderRadius: "100%",
        width: 40,
        height: 40,
      }}
    >
      {count}
    </div>
  );
};

export default BubbleCount;
