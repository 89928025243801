import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../Loader";

const Page = ({
  container = "container",
  containerClassname = "",
  title,
  children,
  errors = null,
  back = null,
  action = null,
  style = { maxWidth: "95%" },
  margin = "my-5",
  titleMargin = "mb-lg-5 mb-md-4 mb-3",
  notAnimated = false,
  isLoading = false,
}) => {
  return (
    <motion.div
      exit={{ opacity: 0, y: -50 }}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        className={
          " page-container " +
          container +
          " " +
          margin +
          " " +
          containerClassname
        }
        style={style}
      >
        {title && (
          <div className={" d-flex align-items-center " + titleMargin}>
            {back && (
              <Link
                to={back}
                className="btn mr-3 shadow-none"
                style={{ padding: "15px 18px" }}
              >
                <i className="fa fa-arrow-left"></i>
              </Link>
            )}

            <h1 className="align-items-center d-flex justify-content-between mb-0 mx-0 text-center w-100">
              {title}
              {action && (
                <Link
                  to={action.to}
                  className="btn btn-primary float-right ml-3"
                >
                  <i className="fa fa-plus mr-2"></i>
                  {action.text}
                </Link>
              )}
              {isLoading && <Loader />}
            </h1>
          </div>
        )}
        {errors && errors.other && (
          <div className="alert alert-danger">{errors.other}</div>
        )}

        {children}
      </div>
    </motion.div>
  );
};

export default Page;
