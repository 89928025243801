import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../../common/Loader";
import PanelToggleBtn from "./PanelToggleBtn";
import ReportParticipations from "./ReportParticipations/ReportParticipations";
import ReportAnswersComparison from "./ReportAnswers/ReportAnswersComparison";
import ReportPhotosComparison from "./ReportPhotos/ReportPhotosComparison";

const ReportComparison = ({ missions }) => {
  const [reports, setReports] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [panelsVisible, setPanelsVisible] = useState([
    "participations",
    "answers",
    "photos",
  ]);
  const [isInitialized, setIsInitialized] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getReport();
  }, [missions]);

  const getReport = () => {
    let requests = [];
    setIsLoading(true);
    missions.forEach((mission) => {
      requests.push(axios.get(`${API_URL}missions/${mission.id}/report`));
    });

    axios
      .all(requests)
      .then(
        axios.spread((...args) => {
          setReports([...args.map((arg) => arg.data)]);
        })
      )
      .catch((err) => console.log(err.response))
      .finally(() => {
        setIsInitialized(true);
        setIsLoading(false);
      });
  };

  const togglePanel = (panel) => {
    if (panelsVisible.includes(panel)) {
      setPanelsVisible(panelsVisible.filter((f) => f !== panel));
    } else {
      setPanelsVisible([...panelsVisible, panel]);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    isInitialized && (
      <div>
        <PanelToggleBtn
          target="participations"
          name="Participations"
          panelsVisible={panelsVisible}
          togglePanel={togglePanel}
        />

        {panelsVisible.includes("participations") &&
          reports &&
          reports.map((report, reportKey) => (
            <ReportParticipations
              key={reportKey}
              report={report}
              title={missions[reportKey].name}
            />
          ))}

        <PanelToggleBtn
          target="answers"
          name="Réponses"
          panelsVisible={panelsVisible}
          togglePanel={togglePanel}
        />

        {panelsVisible.includes("answers") && (
          <ReportAnswersComparison
            reports={reports}
            missionNames={missions.map((m) => m.name)}
          />
        )}

        <PanelToggleBtn
          target="photos"
          name="Photos"
          panelsVisible={panelsVisible}
          togglePanel={togglePanel}
        />

        {panelsVisible.includes("photos") && (
          <ReportPhotosComparison
            missionIds={missions.map((mission) => mission.id)}
            missionNames={missions.map((m) => m.name)}
          />
        )}
      </div>
    )
  );
};

export default ReportComparison;
