import React, { useState } from "react";
import Page from "../../../common/layout/Page";
import MissionListFilters from "./MissionListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import MissionCard from "./MissionCard";
import BubbleCount from "./BubbleCount";
import Control from "../../../common/Controls/Control";

const MissionList = () => {
    const {
        state,
        constants,
        errors,
        setErrors,
        isLoading,
        setIsLoading,
        searchDatas,
        setSearchDatas,
        nbPages,
        setNbPages,
        getSearchDatas,
        deleteData,
        isInit,
        search,
        setSearch,
        otherDatas,
        nbResults,
    } = useApiDatas({
        resourcePath: "missions/prospects",
        page: "MissionList",
        searchParams: {
            mission: { time: "" },
            page: 1,
        },
    });

    const [checked, setChecked] = useState([]);

    console.log(isLoading);
    console.log(searchDatas);

    return (
        <Page
            title={
                <>
                    <span className='d-center'>
                        Constats <BubbleCount count={nbResults} />
                    </span>
                </>
            }
            errors={errors}
            isLoading={isLoading}
            isInit={isInit}>
            <MissionListFilters
                search={search}
                setSearch={setSearch}
                errors={errors}
            />

            <div
                className='row'
                style={{
                    transition: "500ms",
                    opacity: isLoading ? "0.4" : "1",
                }}>
                {searchDatas.map((mission, mk) => (
                    <MissionCard
                        key={`miss${mk}`}
                        mission={mission}
                        isChecked={checked.find((el) => el === mission.id)}
                        handleCheckFn={() => {
                            setChecked(
                                checked.find((el) => el === mission.id)
                                    ? checked.filter((el) => el !== mission.id)
                                    : [...checked, mission.id]
                            );
                        }}
                    />
                ))}
            </div>
            {checked.length > 1 && (
                <div className='mt-5 col-12 d-center'>
                    <Link to={`/constats/${checked.join("/")}`} className='btn btn-primary w-25'>
                        Comparer
                    </Link>
                </div>
            )}
            <Pagination
                nbPages={nbPages}
                page={search.page}
                changePage={(p) => setSearch({ ...search, page: p })}
            />
        </Page>
    );
};

export default MissionList;
