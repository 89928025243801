import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Avatar from "../Avatar";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import Sidenav from "./Sidenav";
import "./topheader.scss";

const TopHeader = () => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  useEffect(() => {}, [state.auth.isAuthenticated]);
  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      console.log("NEW IMAGE !");
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);
  var authData = state.auth;

  const logoutUser = () => {
    localStorage.removeItem("jwtToken");
    window.location.reload();
  };

  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-primary "
        style={{ height: 64 }}
      >
        <div className="container-fluid p-0">
          <img
            className="d-none d-md-block"
            src="/images/logo.svg"
            style={{ width: 90 }}
          />
          <ul className="justify-content-end h-100 m-0 d-flex align-items-center w-100">
            {/* <li className="nav-item mr-2 active">
              <NavLink
                className="nav-link text-primary d-flex flex-row align-items-center"
                to="/me"
              >
                {avatarVisible && (
                  <Avatar
                    className="mr-2"
                    width={25}
                    height={25}
                    id={authData.user.id}
                  />
                )}
                {authData.user.firstname + " " + authData.user.lastname}
              </NavLink>
            </li> */}
            <li
              className="nav-item text-white mr-3 list-unstyled"
              style={{ fontSize: 16, fontWeight: "700", listStyle: "none" }}
            >
              {authData.user.companyName}
            </li>
            <li className="nav-item position-relative list-unstyled">
              <button
                className="bg-transparent border-0"
                onClick={() => setUserMenuOpen(!userMenuOpen)}
              >
                <Avatar width={40} height={40} className="bg-secondary" />
              </button>
              <ul
                className={`list-group user-menu ${
                  userMenuOpen
                    ? "animated fadeInRight faster"
                    : "animated fadeOutRight faster"
                }`}
              >
                <li className="list-group-item">
                  <NavLink
                    onClick={() => setUserMenuOpen(false)}
                    to="/profile"
                    className="bg-primary text-white font-weight-bold"
                  >
                    Voir mon profil
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    onClick={() => setUserMenuOpen(false)}
                    to="/profile"
                    className="bg-primary text-white font-weight-bold"
                  >
                    Modifier mon mot de passe
                  </NavLink>
                </li>

                <li className="list-group-item" onClick={actions.logoutUser}>
                  <span className="bg-primary text-white font-weight-bold">
                    Déconnexion
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  ) : (
    <></>
  );
};

export default TopHeader;
