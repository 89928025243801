import React, { useState } from "react";
import LazyLoad from "./LazyLoad";
import IntersectionVisible from "react-intersection-visible";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Picture = ({ img }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <LazyLoadImage
      alt={img.alt}
      width={img.width}
      src={img.url} // use normal <img> attributes as props
      placeholder={<div style={{ width: 200, height: 200 }}></div>}
    />
  );
};

export default Picture;
