import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import "./sidenav.scss";
import Avatar from "../Avatar";
import { push as Menu } from "react-burger-menu";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Sidenav = withRouter(({ sidenavItems, sidenavOpen, setSidenavOpen }) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      console.log("NEW IMAGE !");
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: "SET_CURRENT_USER",
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = (e) => {
    if (dimensions.width >= 768) {
      var menu = document.querySelector(".bm-menu-wrap");

      if (window.scrollY <= 64) {
        menu.style.top = 64 - window.scrollY + "px";
      } else {
        menu.style.top = "0px";
      }
    }
  };

  useEffect(() => {
    console.log(dimensions.width);
    var isOpen = false;
    if (dimensions.width >= 768) {
      isOpen = true;
    } else {
      isOpen = false;
    }
    setSidenavOpen(isOpen);
    updateLayout(isOpen);
  }, [dimensions]);

  useEffect(() => {
    updateLayout(sidenavOpen);
  }, [sidenavOpen]);

  const updateLayout = (isOpen) => {
    var menuWrap = document.querySelector(".bm-menu-wrap");
    var mainContent = document.querySelector("#main-content");

    if (isOpen) {
      if (menuWrap) {
        menuWrap.classList.remove("minified");
      }
      if (mainContent) {
        mainContent.style.left = "0px";
        if (dimensions.width >= 768) {
          mainContent.style.width = "calc(100% - 300px)";
        } else {
          mainContent.style.width = "100%";
        }
      }
    } else {
      mainContent.style.width = "100%";
      if (dimensions.width >= 768 && menuWrap) {
        menuWrap.classList.add("minified");
        mainContent.style.width = "calc(100% - 75px)";
        mainContent.style.left = "75px";
      }
      if (dimensions.width < 768 && menuWrap) {
        menuWrap.classList.remove("minified");
        mainContent.style.left = "0px";
      }
    }
  };

  // useEffect(() => {
  //   window.$("#sidebar").addClass("active");
  // }, [props.location.pathname]);
  var authData = state.auth;
  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <Menu
      isOpen={sidenavOpen}
      onOpen={() => setSidenavOpen(true)}
      onClose={() => setSidenavOpen(false)}
      noOverlay
      disableOverlayClick
      pageWrapId={"main-content"}
      outerContainerId={"outer-container"}
    >
      <div className="expand-btn" onClick={() => setSidenavOpen(true)}>
        <i className="fa fa-arrow-right" />
      </div>
      <div className="mb-3 sidenav-avatar d-block d-md-none">
        <div className="d-center flex-column">
          <Avatar className="bg-secondary" width={60} height={60} />
          <span className="text-white font-weight-bold mt-2">
            {authData.user.firstname}
          </span>
        </div>
      </div>
      {sidenavItems.map((m, mk) => (
        <NavLink
          className="nav-link d-flex align-items-center justify-content-between"
          to={m.path}
          key={`mui${mk}`}
        >
          <div className="d-flex align-items-center">
            <div className="d-center" style={{ width: 40 }}>
              <i className={`fa ic mr-3 fa-${m.icon}`} />
            </div>
            <span className="nav-item-text">{m.name}</span>
          </div>
          <i className="fa ic-arrow fa-chevron-right" />
        </NavLink>
      ))}
    </Menu>
  ) : (
    <></>
  );
});

export default withRouter(Sidenav);
