import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const AnswerChartBar = ({ question, questionNum, finished }) => {
    const [state, setState] = useState({
        series: question.answers.map((a) => {
            return { name: a.name.toString(), data: [a.nb] };
        }),
        options: {
            labels: question.answers.map((a) => a.name),
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return Math.round((val / finished) * 100) + "%";
                },
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    formatter: function (val) {
                        return Math.round(val);
                    },
                },
            },
            xaxis: {
                tickPlacement: "between",
                labels: {
                    show: false,
                },
            },
        },
    });

    return (
        <div className="border p-2 h-100">
            <div>
                <strong>
                    {questionNum}
                    <i className="fa fa-circle mx-1" style={{ fontSize: 5 }} />
                    {question.name}
                </strong>
            </div>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="bar"
            />
        </div>
    );
};

export default AnswerChartBar;
