import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import Report from "./Report/Report";

const MissionDetail = (props) => {
  var pathId = props.match.params.id;
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    item,
    setItem,
    saveItem,
    otherDatas,
  } = useApiDatas({
    itemPaths: {
      path: "/constats", //url de redirection
      get: "missions/" + pathId, //get item
      post: "missions", //post item
      patch: "missions/" + pathId, //patch item
    },
    dependenciesPath: [],
    itemId: pathId, //create ou id
    itemParams: {},
  });

  const mission = item;
  console.log(isInit);

  return (
    <Page
      container="container"
      back={`/dashboard`}
      title={`${mission.name || ""}`}
      errors={errors}
      contentClassname="mx-auto"
      isInit={isInit}
    >
      {isInit && <Report mission={mission} />}
    </Page>
  );
};

export default MissionDetail;
