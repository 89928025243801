import React, { useState, useEffect } from "react";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import Axios from "axios";
import Loader from "../../../common/Loader";
import Report from "../MissionDetail/Report/Report";
import ReportComparison from "../MissionDetail/Report/ReportComparison";

const MissionComparison = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  let ids = props.match.url.split("/").slice(2);
  const { searchDatas, setSearchDatas, isInit, errors } = useApiDatas({
    ressourcePath: "missions/prospects",
    page: "MissionComparison",
    searchParams: {
      mission: { time: "" },
      page: 1,
    },
  });
  const [missions, setMissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    getMissions(ids);
  }, []);

  const getMissions = (ids) => {
    let requests = [];
    ids.map((id) => {
      requests.push(Axios.get(`${API_URL}missions/${id}`));
    });
    Axios.all(requests)
      .then(
        Axios.spread((...args) => {
          setMissions([...missions, ...args.map((arg) => arg.data)]);
        })
      )
      .finally(setIsInitialized(true));
  };

  return (
    <Page
      container="container"
      back={`/dashboard`}
      title={`Comparaison`}
      errors={errors}
      isInit={isInit}
      contentClassname="mx-auto"
    >
      {isLoading ? (
        <Loader />
      ) : (
        isInitialized && <ReportComparison missions={missions} />
      )}
    </Page>
  );
};

export default MissionComparison;
