import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { API_URL, image_url } from "../../../../../../config";
import Axios from "axios";
import tools from "../../../../../../helpers/tools";
import Picture from "../../../../../common/Picture/Picture";
import Loader from "../../../../../common/Loader";

const ReportPhotosComparison = ({ missionIds, missionNames }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    let requests = [];
    setIsLoading(true);
    missionIds.map((missionId) => {
      requests.push(Axios.get(`${API_URL}missions/${missionId}/images`));
    });

    Axios.all(requests)
      .then(
        Axios.spread((...args) => {
          setImages([...args.map((arg) => arg.data)]);
        })
      )
      .catch((err) => setErrors(err))
      .finally(() => setIsLoading(false));
  };

  const openImage = (url) => {
    window.open(url);
  };

  return (
    <div className="row mx-0 px-3">
      {isLoading ? (
        <Loader />
      ) : images.every((i) => i.length === 0) ? (
        <p>Aucune image disponible</p>
      ) : (
        images
          .filter((i) => i.length !== 0)
          .map((imageArray, index) => (
            <div key={index} className="w-100">
              <p className="font-weight-bold mb-0 mt-4">
                {
                  missionNames[
                    missionIds.indexOf(imageArray[0].fileName.substring(9, 33))
                  ]
                }
              </p>
              <div className="w-100 d-flex">
                {imageArray.map((i, ik) => {
                  let imageUrl = image_url + i.fileName;
                  return (
                    i.fileName && (
                      <div
                        className="col-12 col-md-4 text-center my-2 px-1 cursor-pointer"
                        onClick={() => openImage(imageUrl)}
                        key={`image${i.id}`}
                      >
                        <div className="shadow-sm p-2 h-100">
                          <div className="font-weight-bold">
                            {tools.getFullName(i.uploadedBy)}
                          </div>
                          <div className="font-weight-bold mb-2">
                            le{" "}
                            {DateTime.fromISO(i.createdAt).toFormat(
                              "dd/MM/yyyy hh:mm"
                            )}
                          </div>
                          <Picture
                            img={{
                              url: imageUrl,
                              width: 200,
                              height: 200,
                              alt: "",
                            }}
                          />
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          ))
      )}
    </div>
  );
};

export default ReportPhotosComparison;
