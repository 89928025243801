import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const AnswerChartDonut = ({ question, questionNum }) => {
    console.log("question", question);

    const [state, setState] = useState({
        series: question.answers.map((a) => a.nb),
        options: {
            labels: question.answers.map((a) => `${a.name}`),
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return Math.round(val * 100) / 100 + "%";
                },
            },
            responsive: [
                {
                    breakpoint: 6000,
                    options: {
                        legend: {
                            position: "bottom",
                            horizontalAlign: "left",
                        },
                    },
                },
            ],
        },
    });

    return (
        <div className={`border p-2 h-100`}>
            <div>
                <strong>
                    {questionNum}
                    <i className="fa fa-circle mx-1" style={{ fontSize: 5 }} />
                    {question.name}
                </strong>
            </div>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="donut"
            />
        </div>
    );
};

export default AnswerChartDonut;
