import React from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";

const CustomModal = () => {
  const [state, dispatch] = useStore();
  const actions = modalActions(state, dispatch);
  const modalProps = state.modal;

  return (
    <div
      className="position-fixed w-100 h-100 align-items-center justify-content-center"
      style={{
        display: modalProps.isOpen ? "flex" : "none",
        top: 0,
        left: 0,
        background: "rgb(0 0 0 / 54%)",
        zIndex: "9999",
        overflowY: "auto",
      }}
    >
      <div
        className={
          "bg-white position-relative p-5 " +
          (modalProps.isOpen ? "animated zoomIn faster" : "")
        }
        style={{
          width: "90%",
          height: "90%",
          maxWidth: "400",
          borderRadius: 5,
          overflowY: "scroll",
        }}
      >
        {modalProps.content}

        <div
          className="d-flex align-items-center justify-content-end w-100"
          style={{ position: "fixed", bottom: 0, left: 0 }}
        >
          {modalProps.actions}
          <button
            onClick={() =>
              actions.updateModal({ isOpen: false, content: null })
            }
            className="btn btn-default w-100"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
