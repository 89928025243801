import React from "react";
import ParticipationChart from "./ParticipationChart";

const ReportParticipations = ({ report, title = null }) => {
  const { finished, progress, totalExpected } = report.participations;

  return (
    <div className={`d-flex flex-center w-100 align-items-center`}>
      <div className="col-12 col-md-6">
        {title && <p className="mt-4 font-weight-bold">{title}</p>}
        <ul className="list-group">
          <li className="list-group-item d-flex align-items-center justify-content-between">
            En cours <strong>{progress}</strong>
          </li>
          <li className="list-group-item d-flex align-items-center justify-content-between">
            Terminées <strong>{finished}</strong>
          </li>
          <li className="list-group-item d-flex align-items-center justify-content-between">
            Total{" "}
            <strong>
              {progress + finished} / {totalExpected}
            </strong>
          </li>
        </ul>
      </div>
      <div className="col-12 col-md-6">
        <ParticipationChart participations={report.participations} />
      </div>
    </div>
  );
};

export default ReportParticipations;
