import React from "react";
import useStore from "../../../../context/useStore";
import Control from "../../../common/Controls/Control";

const MissionListFilters = ({ search, setSearch, errors }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <Control
          label="Filtrer par statut"
          type="btnList"
          name="time"
          value={search.mission.time}
          datas={[
            { id: "futur", name: "En attente" },
            { id: "progress", name: "En cours" },
            { id: "finished", name: "Terminé" },
          ]}
          dataIndex="id"
          dataLabel="name"
          btnInline
          change={(e) =>
            setSearch({
              ...search,
              mission: { time: e.target.value },
            })
          }
          error={errors}
        />
      </div>
      <div className="col-12 col-md-4"></div>
      <div className="col-12 col-md-4"></div>
    </div>
  );
};

export default MissionListFilters;
