import React, { useRef, useEffect, useState } from "react";
import Axios from "axios";
import { AVATAR_URL } from "../../config";
import useStore from "../../context/useStore";

const Avatar = ({
  width = 200,
  height = 200,
  className = "",
  imageUrl = false,
}) => {
  // useEffect(() => {
  //   if (id) {
  //     getImage();
  //   } else {
  //     if (imageUrl) setImageSrc(imageUrl);
  //   }
  // }, [id]);
  const [state, dispatch] = useStore();
  const name = state.auth.user.companyName;
  const [imageSrc, setImageSrc] = useState("/images/default-avatar.jpg");

  // const getImage = async () => {
  //   let source = await Axios.get(AVATAR_URL + id).catch((err) => {
  //     return { data: imageSrc };
  //   });

  //   if (source.status !== 200) {
  //     //gestion erreur
  //   }
  //   setImageSrc(source.data + "?" + new Date().toISOString());
  // };
  var matches = name ? name.match(/\b(\w)/g) : ""; // ['J','S','O','N']
  var acronym = matches.length ? matches.join("") : "";

  return (
    <div
      className={
        "shadow-sm avatar text-white d-center font-weight-bold shadow " +
        className
      }
      style={{
        borderRadius: "100%",
        overflow: "hidden",
        width: width,
        height: height,
        textAlign: "center",
        minWidth: width,
        minHeight: height,
      }}
    >
      {acronym}
    </div>
  );
};

export default Avatar;
