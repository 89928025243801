import React, { useEffect, useState } from "react";
import dataService from "../../../../../helpers/dataService";
import Loader from "../../../../common/Loader";
import PanelToggleBtn from "./PanelToggleBtn";
import ReportAnswers from "./ReportAnswers/ReportAnswers";
import ReportFile from "./ReportFile/ReportFile";
import ReportParticipations from "./ReportParticipations/ReportParticipations";
import ReportPhotos from "./ReportPhotos/ReportPhotos";

const Report = ({ mission }) => {
  const [report, setReport] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [panelsVisible, setPanelsVisible] = useState([
    "participations",
    "answers",
    "photos",
  ]);

  useEffect(() => {
    getReport();
  }, []);

  const getReport = () => {
    dataService.get(`missions/${mission.id}/report`, setReport, setErrors, () =>
      setIsLoading(false)
    );
  };

  const togglePanel = (panel) => {
    if (panelsVisible.includes(panel)) {
      setPanelsVisible(panelsVisible.filter((f) => f !== panel));
    } else {
      setPanelsVisible([...panelsVisible, panel]);
    }
  };

  var errorsArr = Object.values(errors);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {errorsArr.length > 0 && (
        <div className="alert alert-danger">{errorsArr}</div>
      )}
      <PanelToggleBtn
        target="participations"
        name="Participations"
        panelsVisible={panelsVisible}
        togglePanel={togglePanel}
      />

      {panelsVisible.includes("participations") && report && (
        <ReportParticipations report={report} />
      )}
      {report.sections?.length > 0 && (
        <>
          <PanelToggleBtn
            target="answers"
            name="Réponses"
            panelsVisible={panelsVisible}
            togglePanel={togglePanel}
          />

          {panelsVisible.includes("answers") && (
            <ReportAnswers report={report} />
          )}

          <PanelToggleBtn
            target="photos"
            name="Photos"
            panelsVisible={panelsVisible}
            togglePanel={togglePanel}
          />

          {panelsVisible.includes("photos") && (
            <ReportPhotos missionId={mission.id} />
          )}

          <div className="col-12 mt-5">
            <ReportFile mission={mission} buttonClassName="w-100" />
          </div>
        </>
      )}
    </div>
  );
};

export default Report;
