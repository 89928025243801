import React, { useState, useEffect } from "react";
import AnswerChartWrapper from "./AnswerChartWrapper";

const ReportAnswersComparison = ({ reports, missionNames }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (reports.length > 0) setIsInit(true);
  }, [reports]);

  const getScore = (score) => {
    let stars = [];
    for (let i = 0; i < 5; ++i) {
      if (i < score) {
        stars.push(<i className="fa fa-star" />);
      } else {
        stars.push(<i className="far fa-star" />);
      }
    }
    return stars;
  };

  const getLongestReport = (reports) => {
    let sectionLength = [];
    if (reports.length > 0) {
      reports.map((r) => sectionLength.push(r.sections.length));
      return sectionLength.indexOf(
        sectionLength.reduce((a, b) => Math.max(a, b))
      );
    }
  };

  return (
    <>
      {isInit &&
        reports[getLongestReport(reports)].sections.map((r, rk) => (
          <div key={rk} className="row mx-0 px-3">
            <div
              className="col-12 d-flex bg-primary text-white py-3 w-100 font-weight-bold justify-content-between"
              style={{ cursor: "pointer" }}
              onClick={() => setIsVisible(isVisible === r.id ? false : r.id)}
            >
              <div>
                SECTION : {r.name}
                <i
                  className={`fa fa-caret-${
                    isVisible === r.id ? "up" : "down"
                  } ml-2`}
                />
              </div>
              <div>
                {reports.map((report, reportKey) => (
                  <>
                    {report.sections
                      .filter((el) => el.name === r.name)
                      .map((s, sk) => (
                        <span key={sk} className="mr-2">
                          {missionNames.filter(
                            (el) => missionNames.indexOf(el) === reportKey
                          ) + " "}
                          {getScore(s.score)}
                        </span>
                      ))}
                  </>
                ))}
              </div>
            </div>
            {isVisible === r.id &&
              reports.map((report, reportKey) => (
                <>
                  {report.sections
                    .filter((el) => el.name === r.name)
                    .map((s, sk) => (
                      <div key={sk} className="col-12">
                        <div>
                          <p className="font-weight-bold mb-0 mt-4 ml-4">
                            {missionNames.filter(
                              (el) => missionNames.indexOf(el) === reportKey
                            )}
                          </p>
                        </div>
                        <div className="d-flex col-12">
                          {s.questions.map((q, qk) => {
                            return ["2", "3", "4", "6"].includes(
                              q.type + ""
                            ) ? (
                              <AnswerChartWrapper
                                key={`report${reportKey}${qk}`}
                                question={q}
                                questionNum={qk + 1}
                                finished={report.participations.finished}
                              />
                            ) : null;
                          })}
                        </div>
                      </div>
                    ))}
                </>
              ))}
          </div>
        ))}
      <div className="row mx-0 px-3">
        <div className="col-12 d-flex bg-secondary text-white py-3 flex-row w-100 font-weight-bold justify-content-between">
          <div>NOTE GLOBALE :</div>
          <div>
            {reports.map((report, reportKey) => (
              <span key={reportKey} className="mr-2">
                {missionNames.filter(
                  (el) => missionNames.indexOf(el) === reportKey
                ) + " "}
                {getScore(report.score)}
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportAnswersComparison;
