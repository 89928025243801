import React from "react";

const MissionStatus = ({ status }) => {
  var className = "";
  var text = "";
  var icon = "";
  switch (status) {
    case "futur":
      className = "bg-success text-white";
      text = "En attente";
      icon = "fa fa-pause";
      break;
    case "progress":
      className = "bg-primary text-white";
      text = "En cours";
      icon = "fa fa-play";
      break;
    case "finished":
      className = "bg-secondary text-white";
      text = "Terminé";
      icon = "fa fa-stop";
      break;
  }

  return (
    <div
      className={`text-white p-3 d-center text-center font-weight-bold ${className}`}
      style={{ fontSize: 20 }}
    >
      <i className={`${icon} mr-2`} />
      {text}
    </div>
  );
};

export default MissionStatus;
