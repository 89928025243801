import Axios from "axios";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../../../../config";
import dataService from "../../../../../../helpers/dataService";
import Loader from "../../../../../common/Loader";

const ReportFile = ({ mission, buttonClassName = "" }) => {
  const [report, setReport] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isGettingReport, setIsGettingReport] = useState(false);
  var missionId = mission.id;
  useEffect(() => {
    getReport();
  }, []);

  const getReport = () => {
    setIsGettingReport(true);
    dataService.get(
      `missions/${missionId}/reportfile`,
      (datas) => setReport(datas[0]),
      (err) => {},
      () => setIsGettingReport(false)
    );
  };

  const downloadFile = () => {
    if (isDownloading) return false;
    setIsDownloading(true);
    Axios({
      url: `${API_URL}missions/${missionId}/reportfile/manual`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        // var filename = res.headers["content-disposition"].split("=")[1];
        fileDownload(res.data, `Rapport détaillé - ${mission.name}.pdf`);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  return report ? (
    <button
      onClick={downloadFile}
      className={`btn btn-success d-center  ${buttonClassName}`}
    >
      {isDownloading || isGettingReport ? (
        <Loader color="white" />
      ) : (
        <>
          {" "}
          <i className="fa fa-file-download mr-2" />
          Télécharger le rapport détaillé{" "}
        </>
      )}
    </button>
  ) : (
    <p className="m-0 text-center py-2 bg-default">
      Aucun rapport n'est disponible
    </p>
  );
};

export default ReportFile;
