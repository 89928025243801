import { DateTime } from "luxon";
import React, { createRef, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { Link } from "react-router-dom";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import ReportFile from "../MissionDetail/Report/ReportFile/ReportFile";
import MissionStatus from "./MissionStatus";

const MissionCard = ({ mission, isChecked, handleCheckFn }) => {
    const [state, dispatch] = useStore();
    const constants = state.constants.items;
    const missionLocation = mission.location
        ? [mission.location.coordinates[1], mission.location.coordinates[0]]
        : [0, 0];
    const popupRef = createRef();

    const [zoom, setZoom] = useState(12);
    const mapRef = createRef();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (popupRef.current) console.log(popupRef.current);
    }, [popupRef]);

    var status = tools.hasBegun(mission.beginAt, mission.endAt);

    const handleCheck = () => {
        setChecked(!checked);
    };

    return (
        <div className='col-12 col-md-4 my-3'>
            <div className='border shadow'>
                <div className=''>
                    <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckFn}
                        style={{
                            position: "absolute",
                            top: "15px",
                            left: "30px",
                            fontSize: "1.5rem",
                        }}
                    />
                    <MissionStatus status={status} />
                </div>
                <Map
                    center={missionLocation}
                    zoom={zoom}
                    style={{ height: 300 }}
                    ref={mapRef}
                    className='bx-outset'
                    scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    <Marker ref={popupRef} position={missionLocation}>
                        <Popup>{mission.address}</Popup>
                    </Marker>
                    {/* <Circle
            center={missionLocation}
            fillColor={"blue"}
            radius={mission.radius * 1000}
          /> */}
                </Map>
                <div className='py-3  px-3 d-center text-center'>
                    <strong style={{ fontSize: 17 }}>{mission.name}</strong>
                </div>

                <ul className='list-group'>
                    <li className='list-group-item border-right-0 border-left-0 text-primary bg-default'>
                        <i className='fa fa-clock mr-2' />
                        Du{" "}
                        {DateTime.fromISO(mission.beginAt).toFormat(
                            "dd/MM/yyyy"
                        )}{" "}
                        au{" "}
                        {DateTime.fromISO(mission.endAt).toFormat("dd/MM/yyyy")}
                    </li>

                    <li className='list-group-item border-right-0 border-left-0 text-primary bg-default'>
                        <i className='fa fa-map-marker-alt mr-2' />
                        {mission.address}
                    </li>
                    <li className='list-group-item border-right-0 border-left-0 text-primary bg-default'>
                        <i className='fa fa-file-prescription mr-2' />
                        {mission.nbReport} rapports attendus
                    </li>
                    <li className='list-group-item border-right-0 border-left-0 text-primary bg-default'>
                        <i className='fa fa-stopwatch mr-2' />À compléter en{" "}
                        {mission.answerMaxDelay}h
                    </li>
                </ul>
                <ReportFile mission={mission} buttonClassName='w-100' />
                <Link
                    to={`/constats/${mission.id}`}
                    className='btn btn-primary w-100'>
                    Voir les détails
                </Link>
            </div>
        </div>
    );
};

export default MissionCard;
