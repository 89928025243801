import React, { useState } from "react";
import AnswerChartDonut from "./AnswerChartDonut";
import AnswerChartBar from "./AnswerChartBar";

const AnswerChartWrapper = ({ question, questionNum, finished }) => {
    const [pie, setPie] = useState(true);

    return (
        <div className="col-12 col-md-6 col-lg-4 mb-5">
            <i
                className={`fas fa-${
                    pie ? "chart-bar" : "chart-pie"
                } mt-3 bg-light d-center`}
                onClick={() => setPie(!pie)}
                style={{
                    position: "relative",
                    bottom: 5,
                    cursor: "pointer",
                    height: 25,
                    width: 25,
                    borderRadius: "100%",
                    zIndex: 999,
                }}
            />
            {pie ? (
                <AnswerChartDonut
                    question={question}
                    questionNum={questionNum}
                />
            ) : (
                <AnswerChartBar
                    question={question}
                    questionNum={questionNum}
                    finished={finished}
                />
            )}
        </div>
    );
};

export default AnswerChartWrapper;
